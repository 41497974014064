<template>
  <div class="min-w-screen-lg overflow-auto">
    <div class="flex flex-col min-h-screen relative">
      <div class="flex-grow flex flex-col bg-gray-100">
        <LayoutRequestHeader />
        <router-view class="flex flex-col flex-grow" />
      </div>
      <Notification />
      <CModalAttachmentPreview />
      <CModalAttachmentRemove />
    </div>
  </div>
</template>

<script>
import CModalAttachmentPreview from 'devotedcg-ui-components/Modal/CModalAttachmentPreview.vue';
import CModalAttachmentRemove from 'devotedcg-ui-components/Modal/CModalAttachmentRemove.vue';
import { mapMutations } from 'vuex';

import LayoutRequestHeader from '@/components/Layout/LayoutRequestHeader.vue';
import Notification from '@/components/Notification.vue';

export default {
  name: 'RequestLayout',
  components: {
    LayoutRequestHeader,
    Notification,
    CModalAttachmentRemove,
    CModalAttachmentPreview,
  },

  beforeDestroy() {
    this.clearOrderInfo();
    this.clearPayloadData();
  },
  methods: {
    ...mapMutations({
      clearOrderInfo: 'order/CLEAR_ORDER_INFO',
      clearPayloadData: 'requestCreate/CLEAR_PAYLOAD_DATA',
    }),
  },
};
</script>
