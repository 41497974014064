<template>
  <div class="pt-5 flex flex-col bg-black-100 z-20">
    <div class="px-6 flex flex-col space-y-4 max-w-1600 mx-auto w-full">
      <div class="self-stretch flex items-center justify-between">
        <portal-target name="head:breadcrumbs" />
        <portal-target name="head:breadcrumbs:append" />
      </div>
      <div class="flex flex-row items-center space-x-4" :class="headerJustifyContentClass">
        <div class="text-2xl text-white font-semibold flex-shrink-0">
          <portal-target name="head:title" />
        </div>
        <div class="flex flex-row items-center">
          <portal-target name="head:navigation" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestLayoutHeader',
  computed: {
    breadcrumbs() {
      return [
        {
          label: this.$t('Asset requests.Asset requests'),
        },
        {
          label: this.$t('Asset requests.My requests'),
        },
        {
          label: (this.routes.find(({ name }) => this.$route.name === name) || {}).title || '',
        },
      ];
    },
    headerJustifyContentClass() {
      return (this.$route.params.id && 'justify-between') || 'justify-center';
    },
  },
};
</script>
